import React, { PureComponent, Children } from "react";

class If extends PureComponent {
  static True = ({ children }) => children;
  static False = ({ children }) => children;

  render() {
    const { is, children } = this.props;
    const finalCondition = is;
    const trueRenders = [];
    const falseRenders = [];

    Children.forEach(children, child => {
      switch (child.type) {
        case this.True: {
          trueRenders.push(child);
          break;
        }
        case this.False: {
          falseRenders.push(child);
          break;
        }
        default: {
          finalCondition && trueRenders.push(child);
        }
      }
    });
    return finalCondition ? trueRenders : falseRenders;
  }
}

export default If;