import axios from 'axios';
import { baseURI } from './config';

const xhr = axios.create({
  baseURL: baseURI,
});

export const Restaurants = {
  list: ({
    skip, limit, name, lat, long, centerLat, centerLong, distance, categories
  }) => xhr.get('/restaurants', {
    params: {
      skip, limit, name, lat, long, centerLat, centerLong, distance, categories
    }
  }),
  getTotal: () => xhr.get('/restaurants/count', {})
};

export default {
  Restaurants,
};
