/* eslint-disable react/prop-types */
import React from 'react';
import { motion } from 'framer-motion';
import ReactGA from 'react-ga';
import css from './card.module.css';
import classnames from "classnames"

const Card = ({
  id,
  name,
  image,
  description,
  giftCard,
  site,
  category,
  address,
  borough,
  createdAt,
  donate,
  orderLink,
  tab
}) => {
  return (
    <motion.div
      key={id}
      className={css.card}
      initial={{
        opacity: 0
      }}
      animate={{
        opacity: 1
      }}
      exit={{
        opacity: 0
      }}
    >
      <div className={css.info}>
        <div>
          <div className={css.title} title={name}>
            <a className={classnames(css.name, 'restaurant-link')} href={site} target="_blank" rel="noopener noreferrer">
              {name}
            </a>
          </div>
          <div className={css.desc}>
            <div className={css.mods}>
              <span className={css.address} title={address}>{address}</span>
              {/* <span className={css.description} title={description}>{description}</span> */}
            </div>
          </div>
        </div>
        <div className={css.actions}>
          {tab === "giftCard" &&
            <>
              <a
                className={classnames(css['gift-link'], 'purchase-button', css.button)}
                title={name}
                href={giftCard}
                onClick={() => {
                  ReactGA.pageview(giftCard);
                }}
                target="_blank"
                rel="noopener noreferrer"
              >
                Buy Gift Card
          </a>
              {
                donate && <a
                  className={classnames(css['donate-link'], 'donate-button', css.button)}
                  title={name}
                  href={donate}
                  onClick={() => {
                    ReactGA.pageview(donate);
                  }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Donate Now
          </a>
              }
            </>
          }
          {
            (tab === 'order' || tab === 'blackOwned') && <a
              className={classnames(css['order-link'], 'order-button', css.button)}
              title={name}
              href={orderLink}
              onClick={() => {
                ReactGA.pageview(orderLink);
              }}
              target="_blank"
              rel="noopener noreferrer"
            >
              Order Online
          </a>
          }
        </div>
      </div>
    </motion.div>
  );
};
export default Card;
