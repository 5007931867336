import React from 'react';
import css from './header.module.css';
import MainStLogo from '../../images/logo1.svg';
import Lb1 from '../../images/logo.svg';
import Lb2 from '../../images/logo2.svg';
import Lb3 from '../../images/logo3.svg';
import Twitter from '../../images/twitter-icon.svg';
import Instagram from '../../images/instagram-icon.svg';
import footerLogo from '../../images/footerLogo.svg';
import medium from '../../images/medium.svg';

const msg = "Help your local business by ordering online, buying gift cards, or donating directly to provide critical cash support during the COVID-19 crisis. Powered by a team of 100% volunteers sending 100% of proceeds to local businesses. @helpmainstreet helpmainstreet.com";

const Header = () => (
  <header className={css.header}>
    <div className={css.section}>
      <div className={css['nav-left']}>
        <div className={css['logo-lb']}>
          <a href="https://medium.com/@nihalmehta/help-main-street-109f3ad1ae6">
            <img src={MainStLogo} alt="Lunchbox Logo" width={170} />
          </a>
        </div>
        {/* <div className={css.logo}>
          <a href="https://lunchbox.io">
            <img className={css.logo} style={{ height: '20px' }} src={Lb2} alt="Lunchbox Logo" />
          </a>
        </div> */}

      </div>
      <div className={css['nav-right']}>
        <a className="" href="  https://medium.com/@nihalmehta/help-main-street-109f3ad1ae6" target="_blank" rel="noopener noreferrer">
          <img className={css.logo} style={{ height: '20px' }} src={medium} alt="@helpmainstreet" />
        </a>
        <a className="" href="https://www.instagram.com/helpmainstreet/" target="_blank" rel="noopener noreferrer">
          <img className={css.logo} style={{ height: '25px' }} src={Instagram} alt="@helpmainstreet" />
        </a>
        <a className={css.twitter} href={encodeURI(`https://twitter.com/intent/tweet?text=${msg}`)} target="_blank" rel="noopener noreferrer">
          <img className={css.logo} style={{ height: '20px' }} src={Twitter} alt="@helpmainstreet" />
          &nbsp;
          <span className={css.handle}>
            @helpmainstreet
          </span>
        </a>
      </div>
      <div className={css['nav-footer']}>
        <div className={css['logo-lb-footer']}>
          <a href="https://lunchbox.io">
            <img src={footerLogo} alt="Lunchbox Logo" width={170} />
          </a>
        </div>
      </div>
    </div>
  </header>
);


export default Header;
