import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';
import App from './App';
import Preload from './Preload';
import '@lunchboxinc/lunchbox-components/dist/style.css';
import * as serviceWorker from './serviceWorker';
import * as Sentry from '@sentry/browser';
import { GOOGLE_TAG_MANAGER, ENV } from './config';
import TagManager from 'react-gtm-module';



// if (ENV === 'production') {
  if (window.location.pathname.split('/')[1].toLowerCase() === 'tablesfortomorrow') {
    TagManager.initialize({
      gtmId: GOOGLE_TAG_MANAGER.tablesForTomorrow,
    });
  } else {
    TagManager.initialize({
      gtmId: GOOGLE_TAG_MANAGER.default,
    });
  }
  Sentry.init({ dsn: 'https://3cdafde96c58447c992ad4e4d958753e@sentry.io/4920697' });
// }

ReactDOM.render((
  <Router>
    <Preload>
      <Switch>
        <Route path="/">
          <App />
        </Route>
      </Switch>
    </Preload>
  </Router>
), document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
