const {
  REACT_APP_GOOGLE_MAPS = 'AIzaSyDDp__7dinJ-_hqnj9iBjFD6acYRrY46I4',
  REACT_APP_ENV = 'development',
} = process.env;

export const ENV = REACT_APP_ENV;
export const GOOGLE_ANALYTICS = {
  default: 'UA-132677736-3',
  tablesForTomorrow: 'UA-161156839-2'
}
export const GOOGLE_TAG_MANAGER = {
  default: 'GTM-T6PJ48H',
  tablesForTomorrow: 'GTM-TTH3ZKM'
}
export const ZOOM = 14.5;
export const DEFAULT_GEO = { lat: 40.7462127, long: -73.9897819 };
export const TYPEFORM = 'https://lunchboxtech.typeform.com/to/zMOywb';

export const HEADER_TEXT = "Help over 120k local businesses in all 50 states by ordering online, buying gift cards, or donating directly to provide critical cash support during the COVID-19 crisis."
export const HEADER_TEXT_PART2 = "We are a team of 100% volunteers sending 100% of proceeds to local businesses."
export const PLACEHOLDER_TEXT = "Search 121,828  merchants by city or zip"


export const GOOGLE_MAPS = (() => {
  switch (REACT_APP_ENV) {
    case 'development': return REACT_APP_GOOGLE_MAPS;
    case 'production':
    default: return 'AIzaSyCEcppofTm1yGa23ufm7i_yNV-xZ7YX3XM';
  }
})();

export const baseURI = (() => {
  switch (REACT_APP_ENV) {
    case 'production': return 'https://gifts.lunchbox.io';
    case 'development': return 'https://gifts.lunchbox.dev';
    default: return 'http://localhost:3000';
  }
})();
