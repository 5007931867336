/* eslint-disable react/prop-types */
import { useScript } from 'hooks';
import { GOOGLE_MAPS } from './config';

const Preloader = ({ children }) => {
  const { loaded, err } = useScript(`https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS}&v=3.exp&libraries=places`);

  if (!loaded || err) {
    return null;
  }

  return children;
};

export default Preloader;
